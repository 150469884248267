/*-----------------------------------------------
    Generated by SwaggerToTS
    Do not customize this file, update the generator
    Copyright C Space 2024
-----------------------------------------------*/
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { appId } from '../azure/auth';
import {
    getAuthToken,
    clientApiScope,
    clientApiUri
} from '../azure/auth';
import {
    AudienceChartViewModelPagedList,
    AudienceChartStatus,
    AudienceChartType,
    AudienceChartListOrder,
    AudienceChartFetchListParams,
    AudienceChartViewModel,
    AudienceChartInputModel,
} from './ClientApiTypes';

/**
 * Returns all audience charts.
*/
export async function audienceChartFetchList(clientId: string, accountId: string, input: AudienceChartFetchListParams): Promise<AudienceChartViewModelPagedList> { 
    const token = await getAuthToken([clientApiScope]);
    const q: string[] = [];
    if (input.status) { q.push(`status=${encodeURIComponent(input.status)}`); }
    if (input.type) { q.push(`type=${encodeURIComponent(input.type)}`); }
    if (input.accountDataProviderId) { q.push(`accountDataProviderId=${encodeURIComponent(input.accountDataProviderId)}`); }
    if (input.page) { q.push(`page=${encodeURIComponent(input.page)}`); }
    if (input.pageSize) { q.push(`pageSize=${encodeURIComponent(input.pageSize)}`); }
    if (input.orderBy) { q.push(`orderBy=${encodeURIComponent(input.orderBy)}`); }
    const p = q.length > 0 ? `?${q.join('&')}` : '';
    const resp = await axios.get<AudienceChartViewModelPagedList>(
        `${clientApiUri}/AudienceChart/${clientId}/${accountId}${p}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Create new audience chart.
*/
export async function audienceChartCreate(clientId: string, accountId: string, input: AudienceChartInputModel): Promise<AudienceChartViewModel> { 
    const token = await getAuthToken([clientApiScope]);
    const resp = await axios.post<AudienceChartViewModel>(
        `${clientApiUri}/AudienceChart/${clientId}/${accountId}`,
            input,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

/**
 * Get audience chart by Id.
*/
export async function audienceChartFetchById(clientId: string, accountId: string, audienceChartId: string): Promise<AudienceChartViewModel> { 
    const token = await getAuthToken([clientApiScope]);
    
    const resp = await axios.get<AudienceChartViewModel>(
        `${clientApiUri}/AudienceChart/${clientId}/${accountId}/${audienceChartId}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

/**
 * Delete audience chart
*/
export async function audienceChartDeleteById(clientId: string, accountId: string, audienceChartId: string): Promise<void> { 
    const token = await getAuthToken([clientApiScope]);
    const resp = await axios.delete(
        `${clientApiUri}/AudienceChart/${clientId}/${accountId}/${audienceChartId}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

